@keyframes draw {
    0% {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    from {
        fill-opacity: 0;
    }
    to {
        fill-opacity: 1;
    }
}

.logo path {
    stroke: #000; /* Change to your logo's stroke color */
    stroke-width: 2; /* Adjust based on your logo's design */
    fill: transparent;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 5s forwards;
    animation-iteration-count: infinite;
}
